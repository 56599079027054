.term_condition_head{
    text-align: center;
    
}
.term_condtion_enddata{
    text-align: center;
}
.copyright{
    text-align: center;
    font-style: italic;
}
.Term_condition{
    width: 1100px;
    margin: auto;
}